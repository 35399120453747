<template>
  <div class="new-url-pc">
    <div class="bg">
      <video autoplay loop="loop" muted="muted" class="bg_video">
        <!-- <source src="../assets/img/container/pc_bg1.mp4" type="video/mp4" /> -->
        <source :src="`${imgUrl}/img/container/pcbg2.mp4`" type="video/mp4" />
      </video>
      <div class="container-right">
        <img :src="`${imgUrl}/img/container/container-right.png`" />
      </div>
      <div class="container-left">
        <div class="container-logo">
          <img :src="`${imgUrl}/img/container/container-logo.png`" />
        </div>

        <div class="container-content">
          <div
            class="content-item"
            v-for="(item, index) in pcDown"
            :key="index"
          >
            <div class="headportrait">
              <img :src="item.ranking" />
            </div>
            <div class="speed" :style="item.textcolor">{{ item.speed }}秒</div>
            <div class="circuit">线路{{ index + 1 }}</div>
            <div class="btns" @click="goto(item.domain_url)">立即进入</div>
          </div>
        </div>

        <div class="container-ests">
          <img :src="`${imgUrl}/img/container/container-ests.png`" />
        </div>
      </div>
    </div>
    <!-- <footers></footers> -->
  </div>
</template>

<script>
import footers from '../components/footer'
import { speed, speedCz, jumplink } from '../network/api'
import ping from '../assets/js/ping'

export default {
  name: 'NewUrlPc',
  components: {
    footers
  },
  data() {
    return {
      loading: '重新测速',
      urlData: [],
      adDown: [],
      ioDown: [],
      pcDown: [],
      dates: '2021',
      timets2: null,
      topImg: [
        {
          image: `${this.imgUrl}/footer/footer_top_3.png`,
          width: '42',
          height: '24'
        },
        {
          image: `${this.imgUrl}/footer/footer_top_4.png`,
          width: '51',
          height: '20'
        },
        {
          image: `${this.imgUrl}/footer/footer_top_5.png`,
          width: '40',
          height: '28'
        },
        {
          image: `${this.imgUrl}/footer/footer_top_6.png`,
          width: '28',
          height: '28'
        },
        {
          image: `${this.imgUrl}/footer/footer_top_7.png`,
          width: '54',
          height: '20'
        },
        {
          image: `${this.imgUrl}/footer/footer_top_8.png`,
          width: '38',
          height: '31'
        },
        {
          image: `${this.imgUrl}/footer/footer_top_9.png`,
          width: '63',
          height: '28'
        },
        {
          image: `${this.imgUrl}/footer/footer_top_10.png`,
          //  width:'70',
          height: '55'
        }
      ],
      bottomImg: [
        {
          image: `${this.imgUrl}/footer/footer_bottom_11.png`
        },
        {
          image: `${this.imgUrl}/footer/footer_bottom_22.png`
        },
        {
          image: `${this.imgUrl}/footer/footer_bottom_33.png`
        },
        {
          image: `${this.imgUrl}/footer/footer_bottom_44.png`
        }
      ]
    }
  },
  mounted() {
    window.clearInterval(this.timets2)
    this.speed()
  },
  methods: {
    goto(url) {
      window.open(url)
    },
    pingurl() {
      this.pcDown.forEach((v, i) => {
        ping(v.domain_url).then((res) => {
          v.speed = res / 1000
          v.speedms = res
          if (v.speedms < 1500) {
            v.ranking = require('../assets/img/container/s3.png')
            v.textcolor = 'color:#39B61D'
          } else if (v.speedms >= 1500 && v.speedms < 3000) {
            v.ranking = require('../assets/img/container/s2.png')
            v.textcolor = 'color:#f2a243'
          } else {
            v.ranking = require('../assets/img/container/s1.png')
            v.textcolor = 'color:#df2424'
          }
        })
      })
      this.$forceUpdate()
    },
    speed() {
      speed().then((res) => {
        if (res.data.status_code === 200) {
          res.data.res.forEach((v, i) => {
            v.ranking = require('../assets/img/container/s3.png')
            v.textcolor = 'color:#39b61d'
            v.speed = 0
            if (v.key == 'pc') {
              this.pcDown.push(v)
            }
          })
          this.pingurl()
          this.timets2 = setInterval(() => {
            this.pingurl()
          }, 5000)
          // this.urlData.forEach((v, i) => {
          //   v.urltext = v.domain_url.replace('https://', '')
          //   if (Number(v.speed.split('.')[0]) <= 3) {
          //     v.ranking = require('../assets/img/container/s3.png')
          //   } else if (
          //     Number(v.speed.split('.')[0]) > 3 &&
          //     Number(v.speed.split('.')[0]) <= 8
          //   ) {
          //     v.ranking = require('../assets/img/container/s2.png')
          //   } else {
          //     v.ranking = require('../assets/img/container/s1.png')
          //   }
          // })
        }
      })
    },

    customerClick(e) {
      // 1 是主线  2 是副线
      // console.log("111",window.tidioChatApi)
      switch (e) {
        case 1:
          if (window.tidioChatApi) {
            window.tidioChatApi.show()
            window.tidioChatApi.open()
          } else {
            this.$confirm({
              title: '提示',
              content: '加载中，请稍后重试',
              onOk() {}
            })
          }
          break
        case 2:
          if (window.fcWidget) {
            window.fcWidget.open()
          } else {
            this.$confirm({
              title: '提示',
              content: '加载中，请稍后重试',
              onOk() {}
            })
          }
          break
      }
    }
  }
}
</script>

<style scoped lang="less">
@import url('../assets/url.less');

.new-url-pc {
  // .header {
  //   width: 100%;
  //   height: 90px;
  //   background-color: #ffffff;

  //   .center {
  //     width: 1310px;
  //     height: 90px;
  //     margin: 0 auto;
  //     display: flex;
  //     align-items: center;

  //     .logo {
  //       width: 220px;
  //     }
  //   }
  // }
  .bg_video {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  video {
    object-fit: cover;
  }
  .bg {
    width: 100%;
    min-width: 1350px;
    overflow: hidden;
    height: 100vh;
    position: relative;
    background: url('https://sabbont.gz.bcebos.com/paspages/img/container/bg.jpg')
      no-repeat;
    background-size: 100% 100%;
    // padding: 60px 0;
    display: flex;
    align-items: flex-end;
    // align-items: center;
    justify-content: center;
    z-index: 2;

    .container-right {
      width: 450px;

      position: relative;
      z-index: 10;

      img {
        width: 100%;
        display: flex;
      }
    }

    .container-left {
      position: relative;
      z-index: 10;

      // width: 800px;
      padding-top: 40px;

      .container-logo {
        width: 450px;
        margin: 0 auto;
        margin-bottom: 10px;

        img {
          width: 100%;
        }
      }

      .container-ests {
        width: 500px;
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 40px;

        img {
          width: 100%;
        }
      }

      .container-content {
        width: 700px;
        // min-height: 442px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        flex-wrap: wrap;

        .content-item {
          width: 150px;
          background-color: rgba(255, 255, 255, 0.5);
          border-radius: 10px;
          padding: 15px 0;
          margin: 20px 0px;
          margin-right: 13px;
          margin-left: 13px;
          &:nth-child(4n) {
            margin-right: 0;
          }
          .headportrait {
            margin: 0 auto;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 25px;
              height: 25px;
            }
          }

          .speed {
            font-size: 20px;
            text-align: center;
            margin: 10px 0;
            color: red;
          }

          .circuit {
            font-size: 16px;
            color: #2454df;
            text-align: center;
          }

          .btns {
            width: 80%;
            height: 30px;
            background: linear-gradient(0deg, #2a6afd, #63c6ff);
            border-radius: 20px;
            line-height: 30px;
            color: #fff;
            text-align: center;
            margin: 0 auto;
            margin-top: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
