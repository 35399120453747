<template>
  <div class="footer">
    <div class="footer_bot">
      <div class="footer_bot_inner">
        <div class="topImg">
          <div v-for="(item, index) in topImg" :key="index">
            <img style="cursor: pointer;" :src="item.image" @mouseover="overImg(index)" @mouseout="outImg(index)" alt=""
              :height="item.height" :width="item.width">
          </div>
        </div>
      </div>
    </div>
    <p style="color:#7c8797;min-width: 1350px;">拥有菲律宾政府（PAGCOR）颁发的合法执照，是受国际行业协会认可的合法公司。进行注册并娱乐前，请确保您年满18周岁！</p>
    <div class="bottom_img">
      <div style="min-width:1100px;margin: 0 auto;display: flex;justify-content: space-between;">
        <div v-for="(item, index) in bottomImg" :key="index">
          <img @mouseover="overBottomImg(index)" @mouseout="outBottomImg(index)" :src="item.image" alt="">
        </div>
      </div>
    </div>
    <p class="bottom-p" style="color:#7c8797">版权所有 ©2019-{{ dates }} 保留所有权</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dates: '2021',
      topImg: [
        // {
        //   image: `${this.imgUrl}/footer/footer_top_1.png`,
        //   width: '63',
        //   height: '28',
        // },
        // {
        //   image: `${this.imgUrl}/footer/footer_top_2.png`,
        //   width: '60',
        //   height: '26',
        // },
        {
          image: `${this.imgUrl}/footer/footer_top_3.png`,
          width: '42',
          height: '24',
        },
        {
          image: `${this.imgUrl}/footer/footer_top_4.png`,
          width: '51',
          height: '20',
        },
        {
          image: `${this.imgUrl}/footer/footer_top_5.png`,
          width: '40',
          height: '28',
        },
        {
          image: `${this.imgUrl}/footer/footer_top_6.png`,
          width: '28',
          height: '28',
        },
        {
          image: `${this.imgUrl}/footer/footer_top_7.png`,
          width: '54',
          height: '20',
        },
        {
          image: `${this.imgUrl}/footer/footer_top_8.png`,
          width: '38',
          height: '31',
        },
        {
          image: `${this.imgUrl}/footer/footer_top_9.png`,
          width: '63',
          height: '28',
        },
        {
          image: `${this.imgUrl}/footer/footer_top_10.png`,
          //  width:'70',
          height: '55',
        },

      ],
      bottomImg: [
        {
          image: `${this.imgUrl}/footer/footer_bottom_11.png`
        },
        {
          image: `${this.imgUrl}/footer/footer_bottom_22.png`
        },
        {
          image: `${this.imgUrl}/footer/footer_bottom_33.png`
        },
        {
          image: `${this.imgUrl}/footer/footer_bottom_44.png`
        }
      ],

    }
  },
  methods: {
    joinusClick() {
      this.$info({
        title: '提示',
        content: '暂未开放'
      })
    },
    //鼠标进入图片
    overBottomImg(item) {
      this.bottomImg[item].image = require(`../assets/img/footer/footer_bottom_${item + 1}.png`)
    },
    //鼠标离开图片
    outBottomImg(item) {
      this.bottomImg[item].image = require(`../assets/img/footer/footer_bottom_${item + 1}${item + 1}.png`)
    },

    //鼠标进入
    overImg(index) {
      this.topImg[index].image = require(`../assets/img/footer/${index + 3}_active.png`)
    },
    //鼠标离开
    outImg(index) {
      this.topImg[index].image = require(`../assets/img/footer/footer_top_${index + 3}.png`)

    }
  },
  created() {
    var date = new Date;
    this.dates = date.getFullYear()
  }

}
</script>

<style scoped>
.footer {
  width: 100%;
  overflow: hidden;
  background-color: #353f4b;
}

.footer_top {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.footer_bot_inner {
  width: 100%;
  margin: 0 auto;
  max-width: 1350px;
  overflow: hidden;
}

.footer_top_left {
  width: 100%;
  margin: 0 auto;
}

.footer_top_left ul {
  display: flex;
  justify-content: center;
}

.footer_top_left ul li {
  cursor: pointer;
  text-align: center;
  color: #7c8797;
  margin: 0 10px;
}

.footer_top_left ul li a {
  color: #7c8797;
  transition: all 0.3;
}

.footer_top_left ul li a:hover {
  color: #e3af6b;
}

.footer_bot {
  width: 100%;
    min-width: 1350px;
  position: relative;
}

.footer_bot_inner {
  width: 100%;
  border-bottom: 1px solid #3d4655;
  margin-bottom: 35px;
}

.footer_bot_inner .topImg {
  width: 100%;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
}

.footer_bot_inner .topImg img {
  margin: 20px 0;
  display: flex;
}

p {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #7c8797;
  text-align: center;
  line-height: 24px;
}

.bottom_img {

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-width: 1350px;
  margin: 40px auto 45px;
}

.bottom_img>div img {
  width: 186px;
  height: 65px;
  display: flex;
}

.bottom_img>div img:hover {
  width: 186px;
  height: 65px;
}

.bottom-p {
  min-width: 1350px;
  margin-top: 25px;
  padding-bottom: 30px;
}
</style>